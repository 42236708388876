// Import CSS
import '../bootstrap/css/bootstrap.min.css';
import '../css/best-carousel-slide.css';
import '../css/animate.min.css';
import '../css/simplelightbox.css';
import '../css/smoothproducts.css';
import '../css/styles.css';
// Import JS
import 'bootstrap';
// import Navigo from 'navigo';
// import './bootstrap.bundle.min.js';
import './font-awesome-5.11.2-all.min.js';
import './jquery.html5storage.min.js';
// import './clean-blog.js';
// import './smooth.scroll.anchor.js';
import './simplelightbox.min.js';
import './smoothproducts.min.js';
import regeneratorRuntime from "regenerator-runtime"; // Async/Await made available here
import tinycolor from 'tinycolor2';
// import { loadScript } from "@paypal/paypal-js";
// Set new default font family and font color to mimic Bootstrap's default styling
// Chart.defaults.global.defaultFontFamily = 'Metropolis, -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
// Chart.defaults.global.defaultFontColor = "#858796";

var globals,
App = {
	settings: {
		defLatLng: [48.86, 2.33],
		defZoom: 6,
		bottomScrolled: false,
		getFilter: false,
		myEvent: 'loadEvent',
		cart: [], // Will be an array of objects
		i18nIsoCountries: [{country: 'France', code: 'FR'}, {country: 'Deutschland', code: 'DE'}, {country: 'Andorra', code: 'AD'}, {country: 'Austria', code: 'AT'}, {country: 'Belgique', code: 'BE'}, {country: 'Canada', code: 'CA'}, {country: 'España', code: 'ES'}, {country: 'United States', code: 'US'}, {country: 'Finland', code: 'FI'}, {country: 'Greece', code: 'GR'}, {country: 'Ireland', code: 'IE'}, {country: 'Italia', code: 'IT'}, {country: 'Luxembourg', code: 'LU'}, {country: 'Martinique', code: 'MQ'}, {country: 'Nederland', code: 'NL'}, {country: 'Poland', code: 'PL'}, {country: 'Polynésie Française', code: 'PF'}, {country: 'Portugal', code: 'PT'}, {country: 'United Kingdom', code: 'UK'}, {country: 'Sweden', code: 'SE'}, {country: 'Suisse', code: 'CH'}],
		cartBadge: 0,
		serverAddress: "https://codegen.snsolutions.fr/db.php",
		// serverAddress: "https://rassure.store/db_sns.php",
		year: (new Date).getFullYear(),
		reassurance: '',
		previousImpactPrice: 0,
		uploadQrBgImg: false,
		myPage: $.sessionStorage.getItem('myPage'),
		qrId: $.sessionStorage.getItem('qrId'),
		idOrder: $.sessionStorage.getItem('idOrder'),
		idOrderLine: $.sessionStorage.getItem('idOrderLine'),
		idCarrier: $.sessionStorage.getItem('idCarrier'),
		priceTotal: $.sessionStorage.getItem('priceTotal'),
		priceDelivery: $.sessionStorage.getItem('priceDelivery'),
		pictureName: $.sessionStorage.getItem('pictureName'),
		pass: $.localStorage.getItem('pass'),
		id: $.localStorage.getItem('id'),
		idPresta: $.localStorage.getItem('idPresta'),
		id_gender_u: $.localStorage.getItem('id_gender_u'),
		firstname_u: $.localStorage.getItem('firstname_u'),
		lastname_u: $.localStorage.getItem('lastname_u'),
		email_u: $.localStorage.getItem('email_u'),
		phone_u: $.localStorage.getItem('phone_u'),
		birthday_u: $.localStorage.getItem('birthday_u'),
		idAddress: $.localStorage.getItem('idAddress'),
		delivery_address_u: $.localStorage.getItem('delivery_address_u'),
		delivery_address2_u: $.localStorage.getItem('delivery_address2_u'),
		delivery_zip_u: $.localStorage.getItem('delivery_zip_u'),
		delivery_city_u: $.localStorage.getItem('delivery_city_u'),
		delivery_country_u: $.localStorage.getItem('delivery_country_u'),
		idAddressInvoice: $.localStorage.getItem('idAddressInvoice'),
		invoice_address_u: $.localStorage.getItem('invoice_address_u'),
		invoice_address2_u: $.localStorage.getItem('invoice_address2_u'),
		invoice_zip_u: $.localStorage.getItem('invoice_zip_u'),
		invoice_city_u: $.localStorage.getItem('invoice_city_u'),
		invoice_country_u: $.localStorage.getItem('invoice_country_u'),
		idCountry: $.localStorage.getItem('idCountry'),
		active_u: $.localStorage.getItem('active_u'),
		pwd: $.localStorage.getItem('pwd'),
	},
	refreshGlobals: function(data) {
		globals.pass = data.pass;
		globals.id = data.auto_u;
		globals.idPresta = data.customerId;
		globals.id_gender_u = data.id_gender_u;
		globals.firstname_u = data.firstname_u;
		globals.lastname_u = data.lastname_u;
		globals.email_u = data.email_u;
		globals.phone_u = data.phone_u;
		globals.birthday_u = data.birthday_u;
		globals.idAddress = data.addressId;
		globals.delivery_address_u = data.delivery_address_u;
		globals.delivery_address2_u = data.delivery_address2_u;
		globals.delivery_zip_u = data.delivery_zip_u;
		globals.delivery_city_u = data.delivery_city_u;
		globals.delivery_country_u = data.delivery_country_u;
		globals.idAddressInvoice = data.addressInvoiceId;
		globals.invoice_address_u = data.invoice_address_u;
		globals.invoice_address2_u = data.invoice_address2_u;
		globals.invoice_zip_u = data.invoice_zip_u;
		globals.invoice_city_u = data.invoice_city_u;
		globals.invoice_country_u = data.invoice_country_u;
		globals.idCountry = data.idCountry;
		globals.active_u = data.active_u;
	},
	init: function() {
		// kick things off
		globals = this.settings;
		this.bindUIActions();
		// this.getProductList();
		$("#now-date").append(globals.year);
	},
	logMeIn: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		$(myFormDiv).closest('.row').css('opacity', 0.6);
		let query = $(myFormDiv).serialize();
		const req = "login";
		const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder; // Just in case there is an order so we update front_codes with userId.
		query = query + "&idOrder=" + globals.idOrder + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.pass == "OK") {
				$.localStorage.setItem('pass', data.pass);
				$.localStorage.setItem('id', data.auto_u);
				$.localStorage.setItem('idPresta', data.customerId);
				$.localStorage.setItem('id_gender_u', data.id_gender_u);
				$.localStorage.setItem('company_u', data.company_u);
				$.localStorage.setItem('siret_u', data.siret_u);
				$.localStorage.setItem('firstname_u', data.firstname_u);
				$.localStorage.setItem('lastname_u', data.lastname_u);
				$.localStorage.setItem('email_u', data.email_u);
				$.localStorage.setItem('phone_u', data.phone_u);
				$.localStorage.setItem('birthday_u', data.birthday_u);
				$.localStorage.setItem('idAddress', data.addressId);
				$.localStorage.setItem('delivery_address_u', data.delivery_address_u);
				$.localStorage.setItem('delivery_address2_u', data.delivery_address2_u);
				$.localStorage.setItem('delivery_zip_u', data.delivery_zip_u);
				$.localStorage.setItem('delivery_city_u', data.delivery_city_u);
				$.localStorage.setItem('delivery_country_u', data.delivery_country_u);
				$.localStorage.setItem('idAddressInvoice', data.addressInvoiceId);
				$.localStorage.setItem('invoice_address_u', data.invoice_address_u);
				$.localStorage.setItem('invoice_address2_u', data.invoice_address2_u);
				$.localStorage.setItem('invoice_zip_u', data.invoice_zip_u);
				$.localStorage.setItem('invoice_city_u', data.invoice_city_u);
				$.localStorage.setItem('invoice_country_u', data.invoice_country_u);
				$.localStorage.setItem('idCountry', data.id_country_u);
				$.localStorage.setItem('active_u', data.active_u);
				App.refreshGlobals(data);
				$('#userNameCont').empty().append('Bienvenue<br>'+globals.firstname_u+' '+globals.lastname_u);
				$('.connected').show();
				$('.notConnected').hide();
				if(globals.cart.length>0) {
					setTimeout(function(){
						// document.location.href='/delivery';
						document.location.href='/paypage';
					}, 600);
				}
				else {
					setTimeout(function(){
						window.history.back();
					}, 600);
				}
			}
			else {
				if(data.active_u==0) alert("Ce compte a été désactivé !");
				else alert("Identifiant ou mot de passe erroné !");
			}
		}, "json").always(function() {
			$(myFormDiv+' #sender').attr("disabled", false).html('<b><i class="fa fa-unlock"></i> Connexion</b>');
			$(myFormDiv).closest('.row').css('opacity', 1);
		});
	},
	logMeOut: function () {
		$.localStorage.setItem('pass', false);
		$.localStorage.setItem('id', '');
		$.localStorage.setItem('idPresta', '');
		$.localStorage.setItem('id_gender_u', '');
		$.localStorage.setItem('company_u', '');
		$.localStorage.setItem('siret_u', '');
		$.localStorage.setItem('firstname_u', '');
		$.localStorage.setItem('lastname_u', '');
		$.localStorage.setItem('email_u', '');
		$.localStorage.setItem('phone_u', '');
		$.localStorage.setItem('birthday_u', '');
		$.localStorage.setItem('idAddress', '');
		$.localStorage.setItem('delivery_address_u', '');
		$.localStorage.setItem('delivery_address2_u', '');
		$.localStorage.setItem('delivery_zip_u', '');
		$.localStorage.setItem('delivery_city_u', '');
		$.localStorage.setItem('delivery_country_u', '');
		$.localStorage.setItem('idAddressInvoice', '');
		$.localStorage.setItem('invoice_address_u', '');
		$.localStorage.setItem('invoice_address2_u', '');
		$.localStorage.setItem('invoice_zip_u', '');
		$.localStorage.setItem('invoice_city_u', '');
		$.localStorage.setItem('invoice_country_u', '');
		$.localStorage.setItem('idCountry', '');
		$.localStorage.setItem('active_u', false);
		$('.connected').hide();
		$('.notConnected').show();
		setTimeout(function(){
			document.location.href='/'; // Or login.html if multipage
		}, 1000);
	},
	subContact: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let query = $(myFormDiv).serialize();
		const req = "contact";
		query = query + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok")
				returns = '<div class="alert alert-success" role="alert"><b>Votre message a bien été envoyé.</b></div>';
			else
				returns = '<div class="alert alert-danger" role="alert"><b>Votre message n\'a pas été envoyé.</b></div>';
			$(myFormDiv+' #sender').attr("disabled", false).html('Envoyer&nbsp;<i class="fa fa-paper-plane"></i>');
			$(myFormDiv+' #successfail').empty().append(returns);
		}, "json");
	},
	addWasValidatedClass: function (myFormDiv) {
		$(myFormDiv).addClass('was-validated');
	},
	closeModal: function (myModalDiv) {
		$(myModalDiv).modal('hide');
	},
	clearFormFields: function(myFormDiv, event) {
		// if(myFormDiv=='#editPicturesAddressForm') event.preventDefault(); // prevents form submission when button is inside it !
		// $(myFormDiv).find("input[type=text], input[type=tel], input[type=email], input[type=number], input[type=file], textarea, select").val("");
		$(myFormDiv).find("input, textarea, select").val('');
		$(myFormDiv).find("input[type=checkbox]").prop("checked", false);
		$(myFormDiv).find("input[type=radio]").prop("checked", false);
		$(myFormDiv).removeClass('was-validated');
		$(myFormDiv+' #successfail').empty();
	},
	safeJsonParse: function(input) {
		try {
			return JSON.parse(input);
		} catch (e) {
			return undefined;
		}
	},
	urlParam: function(name, url){
		// Get parameters from an URL
		var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
		//For current URL
		//var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
		if (results==null){
			return null;
		}
		else{
			return results[1] || 0;
		}
	},
	setCodeGenPage: function(qrId) {
		App.smoothScrollTo('#codeGenCont', 0);
		$('#qrImgBg').on('change', function() {
			// We have to upload this picture as it is probably a new one...
			globals.uploadQrBgImg = true;
			$('#qrPositionCont').fadeIn();
		});
		// Set the LightBox...
		let lightbox = $('a[data-simplelightbox="all"]').simpleLightbox({
			showCounter : false,
			history : false,
			captionType : 'data',
			captionsData : 'caption',
			maxZoom: 10,
			widthRatio: 1,
			heightRatio: 1,
		});
	},
	checkRadioById: function (radioId, triggerClick) {
		$('#'+radioId).attr('checked', true);
		if(triggerClick) $('#'+radioId).trigger('click');
	},
	emptyThisField: function (myFieldId, event) {
		event.preventDefault();
		$(myFieldId).val(""); // Empty the input field
		$(myFieldId).siblings(".custom-file-label").removeClass("selected").html(''); // Empty Bootstrap's custom-file
	},
	generateCustomCodes: function (myFormDiv) {
		let qrId = globals.qrId;
		if(isNaN(qrId) || globals.qrId==null) qrId = 0; // Just in case but very unlikely to happen as sessionStorage item is only set here.
		const idOrder = (isNaN(globals.idOrder) || globals.idOrder==null) ? 0 : globals.idOrder;
		const id = (isNaN(globals.id) || globals.id==null) ? 0 : globals.id;
		const pictureName = globals.pictureName;
		const qrStyle = $(myFormDiv+' #qrStyle').val();
		const qrColorMain = $(myFormDiv+' #qrColorMain').val();
		const qrColorSec = $(myFormDiv+' #qrColorSec').val();
		let qrColorBg = $(myFormDiv+' #qrColorBg').val();
		let qrOpacityBg = $(myFormDiv+' #qrOpacityBg').val();
		const idOrderLine = $(myFormDiv+' #idOrderLine').val();
		const qrImgBg = $(myFormDiv+' #qrImgBg').val();
		const qrPosition = $(myFormDiv+' #qrPosition').val();
		const qrColorMainIsLight = tinycolor(qrColorMain).isLight();
		const qrColorSecIsLight = tinycolor(qrColorSec).isLight();
		console.warn('qrColorMain: '+qrColorMain+', qrColorSec: '+qrColorSec+', qrColorBg: '+qrColorBg+', idOrder: '+idOrder+', idOrderLine: '+idOrderLine+', qrImgBg: '+qrImgBg+', qrColorMainIsLight: '+qrColorMainIsLight+', qrColorSecIsLight: '+qrColorSecIsLight);
		/*
		if(qrImgBg != '') { // If an image is uploaded we force filter light or dark...
			if(qrColorMainIsLight || qrColorSecIsLight) qrColorBg = '#000000';
			// else if(qrColorMainIsLight && !qrColorSecIsLight) qrColorBg = '#333333';
			// else if(!qrColorMainIsLight && qrColorSecIsLight) qrColorBg = '#CCCCCC';
			else qrColorBg = '#FFFFFF';
			if(qrOpacityBg<=0.5 && qrPosition=='cover') {
				qrOpacityBg = (qrStyle=='dots') ? 0.6 : 0.5;
			}
		}
		else qrOpacityBg = 1; // In case there is no image we set filter opacity to 1
		$(myFormDiv+' #qrColorBg').val(qrColorBg);
		$(myFormDiv+' #qrOpacityBg').val(qrOpacityBg);
		*/
		// const qrColorMainIsReadable = tinycolor.isReadable(qrColorMain, qrColorBg,{level:"AA",size:"large"});
		const contrastFactor = (qrStyle=='dots') ? 2 : 1.5;
		const qrColorMainIsReadable = (tinycolor.readability(qrColorMain, qrColorBg)>contrastFactor) ? true : false;
		const qrColorSecIsReadable = (tinycolor.readability(qrColorSec, qrColorBg)>contrastFactor) ? true : false;
		// const testIsReadable = tinycolor.readability("#999", "#FFF");
		let warning = "";
		warning = (!qrColorMainIsReadable) ? warning+"La couleur principale est trop proche de la couleur du fond !\r\n" : warning;
		warning = (!qrColorSecIsReadable) ? warning+"La couleur secondaire est trop proche de la couleur du fond !\r\n" : warning;
		// warning = (!testIsReadable) ? warning+"La couleur testIsReadable est illisible !\r\n" : warning;
		if(warning != '') alert("Le QrCode risque d'être illisible car :\r\n"+warning);
		// if(qrColorMain==qrColorBg || qrColorSec==qrColorBg) alert("La couleur du QrCode est la couleur de fond ne peuvent pas être les mêmes");
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		$('#qrPreviewCont').empty().append('<img src="https://rassure.store/loading.svg" class="img-fluid" alt="Chargement...">');
		const req = "generateCustomCodes";
		// let query = $(myFormDiv).serialize();
		// query = query + "&req=" + req + "&id=" + globals.id + "&pwd=" + globals.pwd;
		let request = new FormData($(myFormDiv)[0]);
		request.append("id", id);
		request.append("idOrder", idOrder);
		request.append("qrId", qrId);
		request.append("pictureName", pictureName);
		// request.append("pwd", globals.pwd);
		// Do we upload a new picture ?
		if(!globals.uploadQrBgImg && pictureName!=null && qrImgBg!="") {
			request.delete('qrImgBg[]');
			request.append("usePreviousPicture", true);
		}
		globals.uploadQrBgImg = false;
		request.append("req", req);
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data) {
			if(data.ok=="ok") {
				$('#qrPreviewCont').empty().append(data.snippet);
				// $(myFormDiv+' #qrId').val(data.qrId);
				$.sessionStorage.setItem('qrId', data.qrId);
				$.sessionStorage.setItem('pictureName', data.pictureName);
				globals.qrId = data.qrId;
				globals.pictureName = data.pictureName;
				// $('#qrPreviewCont a').attr('href', data.previewURL);
				// $('#qrPreviewCont img').attr('src', data.previewURL);
				$('#validateCustomCodesCont').empty().append('<a class="btn btn-dark btn-block text-white" href="'+data.previewURL['final']+'" target="_blank" download><b><i class="fa fa-download"></i> Télécharger</b></a>');
				$('#validateCustomCodesCont').append('<a class="btn btn-outline-dark col-6" href="'+data.previewURL['png']+'" target="_blank" download><i class="fa fa-download"></i> QrCode PNG</a><a class="btn btn-outline-dark col-6" href="'+data.previewURL['svg']+'" target="_blank" download><i class="fa fa-download"></i> QrCode SVG</a>');
			}
			else
				alert("Une erreur s'est produite !!");
			let lightbox = $('a[data-simplelightbox="all"]').simpleLightbox({
				showCounter : false,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				maxZoom: 10,
				widthRatio: 1,
				heightRatio: 1,
			});
		}).always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<b><i class="fa fa-retweet"></i> Générer mon QrCode</b>');
		});
	},
	qrTypeChange: function(mySelect) {
		const prefix = mySelect.value;
		console.log(prefix);
		// $('#qrValue').val(prefix);
		if(prefix=='http://') { // In this case we just want the prefix to be the placeholder
			$(mySelect).next('input').val('');
			$(mySelect).next('input').attr('placeholder', prefix);
		}
		else {
			$(mySelect).next('input').val(prefix);
			$(mySelect).next('input').attr('placeholder', prefix);
		}
	},
	bindUIActions: function() {
		// In case there is a page refresh we get back the session cart array of Objects
		globals.cart = ($.sessionStorage.getItem('cart') != null) ? App.safeJsonParse($.sessionStorage.getItem('cart')) : [];
		// Disable right click menu
		// window.addEventListener("contextmenu", (e) => e.preventDefault(), false);
		// Is it Mobile device
		if(/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || window.innerWidth<992) globals.isMobile = true;
		// Add the following code if you want the name of the file appear on select
		$(".custom-file-input").on("change", function(e) {
			//const fileName = $(this).val().split("\\").pop();
			let fileName = "";
			//e.target.files.forEach(function(item){ fileName += '"'+item.name+'"';});
			//alert(e.target.files[0].name);
			for (var i = 0; i < e.target.files.length; i++) {
				fileName += '"'+e.target.files[i].name+'" ';
			}
			$(this).siblings(".custom-file-label").addClass("selected").html(fileName);
		});
		// Managing navigation so that we bindUIActions on the right page...
		let url = window.location.pathname;
		globals.myPage = url.substring(url.lastIndexOf('/')+1);
		$.sessionStorage.setItem('myPage', globals.myPage);
		// And set the LightBox...
		let lightbox = $('a[data-simplelightbox="all"]').simpleLightbox({
			showCounter : false,
			history : false,
			captionType : 'data',
			captionsData : 'caption'
		});
		lightbox.refresh();
		// Set smoothScroll on anchors
		this.refreshSmoothScroll();
		// Close Dropdown AccountMenu when one of its links are clicked
		$("#mainMenuAccounDropdown .dropdown-item").on("click", function() {
			$(this).closest('.dropdown-menu').removeClass('show');
		});
		// Show the navbar when the page is scrolled up
		const MQL = 992;
		//primary navigation slide-in effect
		var headerHeight = $('#mainNav').height();
		$(window).on('scroll', {
			previousTop: 0
		}, function() {
			var currentTop = $(window).scrollTop();
			if ($(window).width() > MQL) {
				//check if user is scrolling up
				if (currentTop < this.previousTop) {
					//if scrolling up...
					if (currentTop > 0 && $('#mainNav').hasClass('is-fixed')) {
						$('#mainNav').addClass('is-visible');
					} else {
						$('#mainNav').removeClass('is-visible is-fixed');
					}
				} else if (currentTop > this.previousTop) {
					//if scrolling down...
					$('#mainNav').removeClass('is-visible');
					if (currentTop > headerHeight && !$('#mainNav').hasClass('is-fixed')) $('#mainNav').addClass('is-fixed');
					$('.dropdown-menu').removeClass('show'); // dropdown-menu bugs when on mobile devices (inside mobile collapse)
				}
			}
			else {
				// Mobile version...
				$('#mainNav').removeClass('is-visible').removeClass('is-fixed'); // In case We switched from wide screen to Mobile.
				$('.dropdown-menu').addClass('show'); // dropdown-menu bugs when on mobile devices (inside mobile collapse)
				if (currentTop < this.previousTop) {
					//if scrolling up...
					$('#mainNav').fadeIn("slow");
				} else if (currentTop > this.previousTop) {
					//if scrolling down...
					$('#mainNav').fadeOut("slow");
				}
			}
			this.previousTop = currentTop;
		});
		$('#mainNav .nav-link').on("click", function () {
			if(window.innerWidth < MQL) {
				//$('.navbar').slideToggle("slow");
				$('#mainNav .navbar-collapse').removeClass('show');
				//alert(window.innerWidth);
			}
		});
		document.addEventListener("scroll", function (event) {
			if (App.getDocHeight() == App.getScrollXY()[1] + window.innerHeight) {
				//$('.go-up-fixed').fadeOut('slow');
				globals.bottomScrolled=true;
			}
			else {
				globals.bottomScrolled=false;
				if(App.getScrollXY()[1] == 0) {
					// $('.navbar').slideDown("slow");
					// $('#navbar-collapse').removeClass('show');
					$('.go-up-fixed').fadeOut('slow');
				}
				else
					$('.go-up-fixed').fadeIn('slow');
			}
		});
	},
	refreshSmoothScroll: function() {
		// Smooth Scroll to div on anchors click...
		$('a[href*="#"]').not('a.noscroll').on('click', function (event) {
			event.preventDefault();
			let offset = 0;
			const target = this.hash;
			if($(this).data('offset') != undefined) offset = $(this).data('offset'); // if set data-offset="pixels"
			if($(target).length) {
				$('html, body').stop().animate({
					'scrollTop': $(target).offset().top - offset
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
			else { // Scrolls to top...
				$('html, body').stop().animate({
					'scrollTop': 0
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
		});
	},
	smoothScrollTo: function(target, offset) {
		// Smooth Scroll to div...
        if($(target).length) {
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset
            }, 1600, 'swing', function() {
                // window.location.hash = target;
            });
        }
	},
	getScrollXY: function () {
		var scrOfX = 0, scrOfY = 0;
		if( typeof( window.pageYOffset ) == 'number' ) {
			//Netscape compliant
			scrOfY = window.pageYOffset;
			scrOfX = window.pageXOffset;
		} else if( document.body && ( document.body.scrollLeft || document.body.scrollTop ) ) {
			//DOM compliant
			scrOfY = document.body.scrollTop;
			scrOfX = document.body.scrollLeft;
		} else if( document.documentElement && ( document.documentElement.scrollLeft || document.documentElement.scrollTop ) ) {
			//IE6 standards compliant mode
			scrOfY = document.documentElement.scrollTop;
			scrOfX = document.documentElement.scrollLeft;
		}
		return [ scrOfX, scrOfY ];
	},
	getDocHeight: function () {
		var D = document;
		return Math.max(
			D.body.scrollHeight, D.documentElement.scrollHeight,
			D.body.offsetHeight, D.documentElement.offsetHeight,
			D.body.clientHeight, D.documentElement.clientHeight
		);
	},
};

// Expose App object to window object => need this to use click event in html
window.App = App;
require('./router.js');

(function() {
	App.init();
})();